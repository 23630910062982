import * as React from 'react'
import { GatsbyImage } from "gatsby-plugin-image"

import { overlay, cell} from "./igpost.module.css"

const IgPost = ({ className, item }) => {
  return (
    <div
      className={className}
      style={{ position: 'relative' }}>
      <p className={overlay} onClick={() => window.open(item.node.permalink, '_blank').focus()}>
        {item.node.caption}
      </p>
      <GatsbyImage
        image={item.node.localImage.childImageSharp.gatsbyImageData}
        key={item.node.id}
        alt={
          item.node.caption || 'Instagram Post'
        }
        className={cell}
      />
    </div>
  )
}

export default IgPost;