import * as React from "react"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"

import { graphql } from "gatsby";

import ReactVivus from 'react-vivus';
import message from "../images/i-help-machines-meet-us-where-we-are.svg";
import IgPost from "../components/igpost";

import {
  body,
  row,
  headingRow,
  colDynamic,
  col1,
  col2,
  col3,
  face,
  typewriter,
  shadowed,
  handwriting,
  tile,
  gridRow,
  gridColumn
} from "./index.module.css"


const IndexPage = ({ data }) => {
  return (
    <Layout pageTitle="home">
      <div className={headingRow}>
        <div className={colDynamic}>
          <h1 className={typewriter}>
            Why do you need to act like a machine to use one?
          </h1>
          <ReactVivus
            id={handwriting}
            option={{
              delay: 100,
              file: message,
              type: 'oneByOne',
              animTimingFunction: 'LINEAR',
              duration: 300,
              onReady: console.log
            }}
            callback={console.log}
          />
        </div>
        <div className={face}>
          <StaticImage alt="a picture of me" src="../images/profile_fixed.png" className={shadowed}></StaticImage>
        </div>
      </div>
      <div className={row}>
        <div className={col3}>
          <p>
            I do this by combining interface work with artificial intelligence work. I help the machines understand our world, and help us better understand theirs.
          </p>
          <p>
            I work as a Creative Technologist at the <a href="https://news.samsung.com/us/samsung-design-leader-federico-casalegnos-humanity-centered-design-opportunity/" target="_blank" rel="noopener noreferrer"> Samsung Design Innovation Center</a>, where our team prototypes new devices, wearables, AI assistants, and other future technology focused on the human experience.
          </p>
          <p>
            I also work on <a href="https://madsci.io" target="_blank" rel="noopener noreferrer">MadSci</a>, a nonprofit community makerspace and laboratory I cofounded in the heart of San Francisco.
          </p>
          <p>
            In the past, I developed interpretable models for automated valuations at <a href="http://opendoor.com" target="_blank" rel="noopener noreferrer">Opendoor</a>, researched transfer learning from simulation to the physical world in AI/robotics at <a href="http://openai.com" target="_blank" rel="noopener noreferrer">OpenAI</a>, and led deep learning efforts to build a collaborative human-machine intelligence system at <a href="http://claralabs.com" target="_blank" rel="noopener noreferrer">Clara Labs</a>. I’ve also worked on some old-school <a href="https://www.cepstral.com/en">concatenative speech synthesis</a>, computational neuroscience for brain-computer interfaces, built the Apple Design Award winning app <a href="https://www.popsci.com/technology/article/2013-01/mosaic-very-cool-app-you-will-install-and-then-not-use/">Mosaic</a>, and founded a mobile behavioral analytics <a href="https://techcrunch.com/2013/08/08/yc-backed-watchsend-records-mobile-screen-interactions-for-user-testing">company</a> through Y Combinator.
          </p>
        </div>
      </div>
      <div className={row}>
        <p className={col3}>
          I also make:
          <a href="https://www.youtube.com/watch?v=NPzAbH-HN3w"> volumetric displays</a>,
          <a href="/stories"> sci-fi short stories</a>,
          <a href="/posts/good-intentions-dont-scale"> essays on society</a>,
          <a href="https://neurips.cc/Conferences/2019/Schedule?showEvent=15451"> realtime novel view synthesis</a><a href="http://tarzain.com/images/Deep_Space_Time_Prior_for_Novel_View_Synthesis%20(5).pdf"> research</a>,
          <a href="https://youtu.be/yO0aEv_0Aeo"> multiplayer online math games</a>,
          <a href="http://instagram.com/zainmakes/"> LED lamps</a>,
          <a href="http://instagram.com/zainmakes/"> optical art</a>,
          <a href="http://instagram.com/zainmakes/"> delicious ice cream (dairy-ful &amp; vegan!)</a>, and a bunch of other stuff.
        </p>
      </div>

      <div className={gridRow}>
        {data.allInstagramContent.edges.map(
          (item, idx) =>
            item.node.localImage && (
              <IgPost className={gridColumn} item={item} key={idx} />
            )
        )}
      </div>

      <svg height="0" xmlns="http://www.w3.org/2000/svg">
        <filter id="drop-shadow">
          <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
          <feOffset dx="0" dy="2" result="offsetblur" />
          <feFlood floodColor="var(--shadowColor)" />
          <feComposite in2="offsetblur" operator="in" />
          <feMerge>
            <feMergeNode />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </svg>
    </Layout>
  )
}

export const query = graphql`query {
  allInstagramContent(sort: { fields: [timestamp], order: DESC }){
    edges {
      node {
        id
        permalink
        caption
        media_url
        localImage {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        album {
          localImage {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
}`

export default IndexPage
